/*
  © Copyright, Dexima Inc.
  2023 — All rights reserved.
*/
import Vue from 'vue';
import i18n from '@/i18n';
import App from './App.vue';
import store from '@/store';
import router from '@/router';
import VModal from 'vue-js-modal';
import TextareaAutosize from 'vue-textarea-autosize';
import moment from 'moment';
import VueSocketIO from 'vue-socket.io';
import VueSlider from 'vue-slider-component';
import 'vue-slider-component/theme/default.css';
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import contenteditable from 'vue-contenteditable';
import vueDebounce from 'vue-debounce';

// import VueNoty from 'vuejs-noty';
// import Noty from 'noty';
import VTooltip from 'v-tooltip';
import VueMask from 'v-mask';
import PortalVue from 'portal-vue';
import VueIntercom from 'vue-intercom';
import CustomNotification from '@/plugins/notify/notify.plugin';
import * as Sentry from '@sentry/vue';
import { BrowserTracing } from '@sentry/tracing';
import { ColorPicker, ColorPanel } from 'one-colorpicker';
import { eventBus } from './eventbus';
import { useSnack } from '@/lib/useSnack';

const { $snack } = useSnack();

Vue.prototype.$noty = $snack;

Vue.config.productionTip = false;

Vue.use(new VueSocketIO({
  debug: false,
  connection: `${process.env.VUE_APP_BASE_URL}/all`,
}));

Vue.use(require('vue-moment'), {
  moment,
});
// Vue.use(VueNoty, {
//   timeout: 4000,
//   progressBar: true,
//   layout: 'topRight',
//   closeWith: ['click', 'button'],
// });
// Noty.overrideDefaults({
//   timeout: 4000,
//   layout: 'topRight',
//   progressBar: true,
//   closeWith: ['click', 'button'],
// });
Vue.use(Loading);
Vue.use(VModal);
Vue.use(TextareaAutosize);
Vue.use(vueDebounce, {
  listenTo: 'input',
});
Vue.use(contenteditable);
Vue.use(VTooltip);
Vue.use(PortalVue);

Vue.component('VueSlider', VueSlider);

Vue.use(VueIntercom, {});
Vue.use(VueMask);
Vue.use(ColorPanel);
Vue.use(ColorPicker);
Vue.use(CustomNotification, {
  eventBus,
});
let handleOutsideClick;

Vue.config.errorHandler = function (error, vm, info) {
  // eslint-disable-next-line no-console
  console.error(error, vm, info);
};

Sentry.init({
  Vue,
  dsn: 'https://dc2de03ab3b040968edccc37625e8415@o1045484.ingest.sentry.io/4504084088225792',
  integrations: [
    new BrowserTracing({
      routingInstrumentation: Sentry.vueRouterInstrumentation(router),
      logErrors: true,
      tracingOrigins: [
        'thestack.cynapseanalytics.ai',
        'leads.findabusinesspro.com',
        'leads.salessparx.com',
        'profitjedis.kelbreeconsulting.com',
        'app.rainmakerdigitalsolutions.com',
        'dash.buzz.ai',
        'intelibotcc.exhaleconsulting.com',
        'go.growthboost.net',
        'x.divo.ai',
        'dash.reachinout.co.uk',
        /^\//],
    }),
  ],
  tracesSampleRate: 1.0,
});

Vue.directive('closable', {
  bind (el, binding, vnode) {
    try {
      handleOutsideClick = (e) => {
        e.stopPropagation();
        const { handler, exclude, params, classExclude } = binding.value;
        let clickedOnExcludedEl = false;
        if (classExclude) {
          document.getElementsByClassName(classExclude).forEach(element => {
            if (element?.contains(e.target)) {
              clickedOnExcludedEl = true;
            }
          });
        } else {
          clickedOnExcludedEl = exclude.some((refName) => {
            if (!clickedOnExcludedEl) {
              const excludedEl = vnode.context.$refs[refName] || document.getElementById(refName);
              return excludedEl?.contains(e.target);
            }
            return false;
          });
        }
        if (!el.contains(e.target) && !clickedOnExcludedEl) {
          if (typeof handler === 'function') {
            handler.call(vnode.context, params);
          } else {
            vnode.context[handler]();
          }
        }
      };
      document.addEventListener('mousedown', handleOutsideClick);
      document.addEventListener('touchstart', handleOutsideClick);
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e);
    }
  },

  unbind () {
    document.removeEventListener('mousedown', handleOutsideClick);
    document.removeEventListener('touchstart', handleOutsideClick);
  },
});

Vue.mixin({
  computed: {
    $socialName () {
      if (this.$isBuzzBrand) {
        return 'Social';
      }
      return 'LinkedIn';
    },
    $config () {
      return store.getters['user/user']?.config || null;
    },
    $isBuzzBrand () {
      return store.getters['user/user']?.brand === 'buzz';
    },
    $isDevSpace () {
      return store.getters['account/account']?._id === '601adbd29a8e0086fd0ec2fc';
    },
  },
});

const vueApp = new Vue({
  store,
  router,
  comments: true,
  i18n,
  render: h => h(App),
});
vueApp.$mount('#app');

export { App };
