export const regexpSearch = {
  groupRegex: /^(https?:\/\/)?www.linkedin(.[a-z]+)\/(groups)\/[^/]+\/$/i,
  salesRegex: /^(https?:\/\/)?www.linkedin(\.[a-z]*)\/sales\/search\/people/i,
  recruiterRegex: /^(https?:\/\/)?www.linkedin(\.[a-z]*)\/recruiter\/smartsearch/i,
  regularRegex: /^(https?:\/\/)?www.linkedin(\.[a-z]*)\/search\/results\/people*/i,
  apolloRegex: /^(https?:\/\/)?app.apollo(\.[a-z]*)\/#\/people/i,
  talentRegex: /^(https?:\/\/)?www.linkedin(\.[a-z]*)\/talent\/hire/i,
  eventRegex: /^(https?:\/\/)?www.linkedin(\.[a-z]+)\/(events|video\/event)\/[^/]*\//i,
  postRegex: /^(https?:\/\/)?www.linkedin(\.[a-z]*)\/posts\/([a-z]*)/i,
  updateRegex: /^(https?:\/\/)?www.linkedin(\.[a-z]*)\/feed\/update\/([a-z]*)/i,
  listRegex: /^(https?:\/\/)?www.linkedin(\.[a-z]*)\/sales\/lists\/people\/(.*)/i,
  projectRegex: /^(https?:\/\/)?www.linkedin(\.[a-z]*)\/recruiter\/projects\/(.*)/i,
};
