<template>
  <div
    ref="vldParent"
    class="flex-column flex-gap-8 pb-25 pt-25 pl-2 pr-2"
  >
    <h1 class="text_s text_weight_accent">
      {{ t('inboxPage.chatSection.infoSidebar.campaignInfo.title') }}
    </h1>
    <div v-if="workflow?.name">
      <div class="flex-row-between">
        <p class="text_xs color_tertiary">
          {{ t('inboxPage.chatSection.infoSidebar.campaignInfo.sections.campaign.title') }}
        </p>
        <default-button
          :title="t('actions.open')"
          color="transparent"
          form="rectangle"
          size="s"
          @action="goToCampaign"
        />
      </div>
      <div class="flex-row-between mb-15">
        <h2 class="text_s text_weight_accent">
          {{ workflow?.name }}
        </h2>
        <default-label
          size="xs"
          :label-name="t(`status.${workflow?.status}`) || capitalize(workflow?.status)"
          :custom-type="workflow?.status === 'active' ? 'success' : 'warning'"
        />
      </div>
      <div>
        <p class="text_xs color_tertiary mb-1 mt-1">
          {{ tc('inboxPage.chatSection.infoSidebar.campaignInfo.sections.steps.title') }}
        </p>
        <ul class="flex-column flex-gap-8">
          <campaign-action-status
            v-for="(action, i) in profile.next_workflow_actions"
            :key="i"
            :index="i"
            :action="action"
            :workflow-status="profile.workflow_status"
          />
        </ul>
      </div>
    </div>
    <div
      v-else
      class="flex-column-centered flex-gap-8"
    >
      <i class="bx bx-briefcase color_tertiary icon_size_24" />
      <span class="text_bold text_s">
        {{ t('inboxPage.chatSection.infoSidebar.campaignInfo.notifications.noActiveCampaigns') }}
      </span>
      <span class="color_tertiary text_s">
        {{ t('inboxPage.chatSection.infoSidebar.campaignInfo.notifications.activeCampaignsHere') }}
      </span>
    </div>
    <default-button
      v-if="workflow?.status === 'active'"
      class="margin-center mt-1"
      :title="isActiveWorkflowForProfile ? t('actions.pauseCampaign') : t('actions.resumeCampaign')"
      :icon-name="isActiveWorkflowForProfile ? 'bx-pause' : 'bx-play'"
      color="neutral"
      form="rectangle"
      size="m"
      @action="updateWorkflowStatusForProfile"
    />

    <continue-modal
      :id="profile._id"
      :show.sync="modals.continue"
      :steps="actionsForModal"
      :name="profile.first_name"
      :show-steps="workflow?.sequences_type === 'simple'"
      @resume="resume"
    />
  </div>
</template>
<script setup>
  import DefaultButton from '@/components/base/uiKit/DefaultButton.vue';
  import DefaultLabel from '@/components/base/uiKit/DefaultLabel.vue';
  import CampaignActionStatus from '@/components/inboxPage/info/campaign/CampaignActionStatus.vue';
  import ContinueModal from '@/modals/ContinueModal.vue';
  import { useRouter } from 'vue-router/composables';
  import { useStore } from '@/store';
  import { capitalize } from '@/mixins/capitalizeFilterMixin';
  import { computed, ref } from 'vue';
  import { useI18n } from '@/i18n';
  const props = defineProps({
    profile: {
      type: Object,
      required: true,
    },
  });
  const emit = defineEmits('update-profile');
  const router = useRouter();
  const { t, tc } = useI18n();

  // store section
  const store = useStore();
  const workflowsList = computed(() => store.getters['workflows/workflowsList']);
  const space = computed(() => store.getters['account/space']);
  const selectedCredential = computed(() => store.getters['credentials/selectedCredential']);
  // end

  // other
  const modals = ref({ continue: false });
  const isActiveWorkflowForProfile = computed(() => props.profile.workflow_status === 'active');
  const workflow = computed(() => workflowsList.value.find(w => w._id === props.profile.workflow));
  const actionsForModal = computed(() => {
    return workflow.value?.sequence.map(a => {
      a.done = props.profile.done_sequence_hash.includes(a.id);
      return a;
    });
  });
  // end

  // methods
  function goToCampaign () {
    router.push({
      name: 'edit-campaign',
      params: { id: workflow.value._id },
      query: {
        space: space.value,
        selectedCred: selectedCredential.value._id,
      },
    });
  }
  function updateWorkflowStatusForProfile () {
    if (isActiveWorkflowForProfile.value) {
      emit('update-profile', { workflow_status: 'inactive' });
    } else {
      workflow.value.sequences_type === 'advanced' ? resume() : modals.value.continue = true;
    }
  }
  function resume (index) {
    modals.value.continue = false;
    emit('update-profile', {
      workflow_status: 'active',
      current_workflow_step: index,
    });
  }

</script>
<script>
  export default {
    name: 'CampaignInfo',
  };
</script>
