import { ref } from 'vue';

const $snackbar = ref([]);

const snackbarLimit = 3;
const snackDuration = 3000;

export const useSnack = () => {
  const $snack = {
    /**
     * Create new Snack with custom options
     * @param {object} options - Options for Snack
     * @param {string} [options._id] - Snack `id` (generates automatically)
     * @param {string} [options.icon] - Custom icon (overrides default icon)
     * @param {'error' | 'warning' | 'success'} [options.type] - Snack type which impacts icon color
     * @param {string} options.message - Snack message
     * @param {number} [options.duration] - Timeout in `ms` before auto closing the Snack (default is 3000ms)
    */
    create (options) {
      const snackId = 'snack-' + Date.now();

      $snackbar.value.push({
        _id: snackId,
        duration: snackDuration,
        ...options,
      });

      if ($snackbar.value.length > snackbarLimit) {
        $snackbar.value.shift();
      }
    },

    /**
     * Create new Default Snack
     * @param {string} message - Snack message
    */
    show (message) {
      const options = {
        message,
      };

      this.create(options);
    },

    /**
     * Create new Success Snack
     * @param {string} message - Snack message
    */
    success (message) {
      const options = {
        type: 'success',
        message,
      };

      this.create(options);
    },

    /**
     * Create new Warning Snack
     * @param {string} message - Snack message
    */
    warning (message) {
      const options = {
        type: 'warning',
        message,
      };

      this.create(options);
    },

    /**
     * Create new Error Snack
     * @param {string} message - Snack message
    */
    error (message) {
      const options = {
        type: 'error',
        message,
      };

      this.create(options);
    },

    /**
     * Remove Snack by `id`
     * @param {number} id - Snack id to remove
    */
    remove (id) {
      const snackIndex = $snackbar.value.findIndex(snack => snack._id === id);
      if (snackIndex !== -1) {
        $snackbar.value.splice(snackIndex, 1);
      }
    },
  };

  return {
    $snack,
    $snackbar,
  };
};
