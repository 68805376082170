<template>
  <div class="snack flex-row flex-justify-content-start flex-gap-8">
    <i
      v-if="snack.icon || snack.type"
      class="bx bx-s flex-row"
      :class="[iconClass, iconColorClass]"
    />

    <span class="text_s">
      {{ snack.message }}
    </span>

    <default-button
      class="snack__close-btn color_tertiary margin-left flex-shrink-0"
      color="neutral"
      form="circle"
      icon-name="bx-x bx-xs"
      @action="$snack.remove(snack._id)"
    />
  </div>
</template>

<script setup>
  import { computed, onMounted } from 'vue';
  import DefaultButton from '@/components/base/uiKit/DefaultButton.vue';
  import { useSnack } from '@/lib/useSnack';

  const { $snack } = useSnack();

  const props = defineProps({
    snack: {
      type: Object,
      required: true,
    },
  });

  onMounted(() => {
    if (props.snack.duration) {
      setTimeout(() => {
        $snack.remove(props.snack._id);
      }, props.snack.duration);
    }
  });

  const iconColorClass = computed(() => {
    switch (props.snack.type) {
    case 'success':
      return 'color_success';
    case 'warning':
      return 'color_warning';
    case 'error':
      return 'color_danger';
    default:
      return 'color_tertiary';
    }
  });

  const iconClass = computed(() => {
    if (props.snack.icon) {
      return props.snack.icon;
    }

    switch (props.snack.type) {
    case 'success':
      return 'bx-check-circle';
    case 'warning':
      return 'bx-info-circle';
    case 'error':
      return 'bx-x-circle';
    default:
      return '';
    }
  });
</script>

<style lang="scss">
.snack {
  pointer-events: all;
  min-width: 288px;
  width: max-content;
  min-height: 44px;
  height: max-content;
  background: white;
  border-radius: 8px;
  padding: 12px;
  box-shadow: 0px 8px 16px rgba(35, 44, 96, 0.16);

  &__close-btn {
    min-width: 16px;
    max-width: 16px;
    height: 16px;
  }
}
</style>
