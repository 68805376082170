<template>
  <div
    ref="vldParent"
    class="change-profile-workflow overflow-hidden"
  >
    <header class="flex-column">
      <h1 class="text_l text_weight_accent">
        {{ title }}
      </h1>
      <p class="text_nowrap text_m mt-16 mb-24">
        Select a campaign to move the prospects
      </p>
    </header>
    <div
      class="change-profile-workflow__columns flex-gap-16 overflow-hidden mb-3"
    >
      <section class="overflow-hidden flex-column flex-gap-8 change-profile-workflow__column">
        <p class="text_xs color_tertiary">
          Campaigns
        </p>
        <default-input
          icon="bx-search"
          :placeholder="t('placeholders.search')"
          size="xs"
          @input.native="filterWorkflows"
        />
        <div class="flex-column flex-gap-8 overflow-y-scroll">
          <workflow-card
            v-for="workflow in filteredWorkflows"
            :key="workflow._id"
            class="background_neutral_50"
            :workflow="workflow"
            :is-hovered="true"
            :is-active="chosenWorkflowId == workflow._id"
            @click.native="selectWorkflow(workflow)"
          />
        </div>
      </section>
    </div>
    <div class="flex-row flex-gap-8">
      <default-switcher
        :is-checked="startOnChange"
        size="s"
        @change="changeStart"
      />
      <span class="text_s">
        Enable Campaign for the moved prospects
      </span>
    </div>
    <div class="flex flex-justify-content-end margin-top flex-gap-8">
      <default-button
        color="neutral"
        title="Cancel"
        form="rectangle"
        size="m"
        @action="close"
      />
      <default-button
        color="primary"
        title="Move prospects"
        form="rectangle"
        size="m"
        @action="moveProspects"
      />
    </div>
  </div>
</template>
<script setup>
  import WorkflowCard from '@/components/cards/WorkflowCard.vue';
  import DefaultInput from '@/components/base/uiKit/DefaultInput.vue';
  import DefaultButton from '@/components/base/uiKit/DefaultButton.vue';
  import DefaultSwitcher from '@/components/base/uiKit/DefaultSwitcher.vue';

  import { searchByField } from '@/utils/global/search';
  import { useI18n } from '@/i18n';
  import { useStore } from '@/store';
  import { computed, ref } from 'vue';
  import { useSnack } from '@/lib/useSnack';
  const { $snack } = useSnack();
  const emit = defineEmits(['close', 'change-workflow']);
  const { t } = useI18n();
  const props = defineProps({
    selectedAmount: {
      type: Number,
      default: 0,
    },
  });
  const startOnChange = ref(false);
  const changeStart = () => { startOnChange.value = !startOnChange.value; };
  const title = `Move ${props.selectedAmount} prospects to another Campaign`;
  const store = useStore();
  const workflows = computed(() => store.getters['workflows/workflowsList']);
  const filteredWorkflows = ref(workflows.value.slice());
  const chosenWorkflowId = ref(null);
  const selectWorkflow = (workflow) => {
    if (chosenWorkflowId.value === workflow._id) {
      chosenWorkflowId.value = null;
    } else {
      chosenWorkflowId.value = workflow._id;
    }
  };
  const filterWorkflows = (e) => {
    filteredWorkflows.value = searchByField(e.target.value, workflows.value, 'name');
  };
  const close = () => emit('close');
  const moveProspects = () => {
    if (!chosenWorkflowId.value) {
      $snack.show('Please, choose campaign to move prospects');
    } else {
      emit('change-workflow', chosenWorkflowId.value, startOnChange.value);
      close();
    }
  };
</script>
<style lang="scss">
.change-profile-workflow{
  &__column{
    flex: 1
  }
  &__columns{
    display: flex;
    flex-flow: row;
  }
}
</style>
