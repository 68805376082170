import { render, staticRenderFns } from "./DeleteConfirmationModal.vue?vue&type=template&id=51df8194&scoped=true&"
import script from "./DeleteConfirmationModal.vue?vue&type=script&setup=true&lang=js&"
export * from "./DeleteConfirmationModal.vue?vue&type=script&setup=true&lang=js&"


/* normalize component */
import normalizer from "!../../../temp_divo-ui/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "51df8194",
  null
  
)

export default component.exports