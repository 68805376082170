<!--
  © Copyright, Dexima Inc.
  2023 — All rights reserved.
-->
<template>
  <portal to="body-end-2">
    <modal
      :key="name"
      :name="name"
      :width="width"
      :height="height"
      classes="component"
      :adaptive="true"
      @closed="handleClose"
    >
      <div class="modal">
        <div class="modal__header mb-3 flex-column flex-gap-8">
          <h2 class="text heading_l">
            {{ title }}
          </h2>
          <p class="text text_s secondary-text">
            {{ text }}
          </p>
          <default-button
            class="modal__close-button"
            color="neutral"
            form="circle"
            size="m"
            icon-name="bx-x"
            @action="handleClose"
          />
        </div>
        <div
          v-if="isCustom && !isAdmin"
          class="margin-left modal__buttons "
        >
          <default-button
            color="primary"
            :title=" t('actions.okay')"
          />
        </div>
        <div
          v-else
          class="margin-left modal__buttons"
        >
          <default-button
            color="neutral"
            :title="t('actions.cancel') "
            @action="handleClose"
          />
          <default-button
            color="danger"
            :title="buttonText"
            @action="confirmDelete"
          />
        </div>
      </div>
    </modal>
  </portal>
</template>

<script setup>
  import DefaultButton from '@/components/base/uiKit/DefaultButton.vue';
  import { useModal } from '@/use/useModal';
  import i18n, { useI18n } from '@/i18n';
  import { watch } from 'vue';
  const $modal = useModal();
  const { t } = useI18n();
  const props = defineProps({
    show: {
      type: Boolean,
      default: false,
    },
    text: {
      type: String,
      default: '',
    },
    isAdmin: {
      type: Boolean,
      default: false,
    },
    isCustom: {
      type: Boolean,
      default: false,
    },
    buttonText: {
      type: String,
      default: '',
    },
    name: {
      type: String,
      default: 'deleteConfirmationModal',
    },
    width: {
      type: String,
      default: '420px',
    },
    height: {
      type: String,
      default: 'auto',
    },
    title: {
      type: String,
      default: function () {
        return i18n.t('modals.deleteConfirmation.title');
      },
    },
  });
  const emit = defineEmits('update:show', 'confirm');
  watch(() => props.show, (show) => {
    show
      ? $modal.show(props.name)
      : $modal.hide(props.name);
  });

  function handleClose () {
    emit('update:show', false);
  }
  function confirmDelete () {
    handleClose();
    emit('confirm');
  }
</script>
<script>
  export default {
    name: 'DeleteConfirmationModal',
  };
   </script>
<style lang="scss" scoped>
</style>
