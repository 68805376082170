import i18n from '@/i18n';

export const videos = {
  from_post: {
    title: i18n.t('campaignsPage.editor.search.modal.options.postUrl.video.title'),
    link: 'https://screencast-o-matic.com/u/vqwH/target-post',
  },
  from_event: {
    title: i18n.t('campaignsPage.editor.search.modal.options.event.video.title'),
    link: 'https://screencast-o-matic.com/u/vqwH/target-event',
  },
  from_csv: {
    title: i18n.t('campaignsPage.editor.search.modal.options.csvFile.video.title'),
    link: 'https://screencast-o-matic.com/u/vqwH/csv-upload',
  },
};
