<template>
  <div
    :class="[
      'responser',
      { 'responser_active': active,
        'responser_gray': $route.name === 'my-prospects'
      },
    ]"
  >
    <default-contenteditable
      ref="messageArea"
      v-model="message"
      tag="div"
      :no-n-l="false"
      :no-h-t-m-l="true"
      class="responser__textarea"
      :contenteditable="!isDisabled"
      :data-placeholder="textareaPlaceholder"
      rows="1"
      @focus.native="active = true"
      @blur.native="active = false"
      @keydown.native.enter.exact.prevent
      @keydown.native.enter.exact="sendMessageHandler"
      @keyup.native="handleInput()"
      @click.native="handleInput()"
    />
    <buttons-responser
      class="flex-align-self-end"
      :can-send="!!message.trim()"
      @open-responses="showBlocks.responses = !showBlocks.responses"
      @open-emojies="showBlocks.emojies = !showBlocks.emojies"
      @schedule="showBlocks.schedule = !showBlocks.schedule"
      @send="sendMessageHandler"
    />
    <saved-responses
      v-show="showBlocks.responses"
      data-closable="responses"
      class="responser__saved-responses"
      :show-saved="true"
      :profile="profile"
      @insert="insertResponse"
    />
    <Picker
      v-show="showBlocks.emojies"
      data-closable="emojies"
      class="responser__emojies"
      :data="emojiesData"
      :emoji-size="24"
      :native="true"
      set="native"
      :per-line="8"
      :show-preview="false"
      :show-skin-tones="false"
      :i18n="emojiPickerLocales"
      @select="insertEmoji"
    />
    <schedule-message
      v-show="showBlocks.schedule"
      data-closable="schedule"
      class="responser__schedule"
      @cancel="showBlocks.schedule = false"
      @send="sendMessageHandler"
    />
  </div>
</template>
<script>
  import ButtonsResponser from '@/components/inboxPage/chat/messages/responser/ButtonsResponser';
  import SavedResponses from '@/components/inboxPage/chat/messages/responser/savedResponses/SavedResponses';
  import ScheduleMessage from '@/components/inboxPage/chat/messages/responser/ScheduleMessage';

  import { closableMixin } from '@/mixins/closableMixin.js';
  import { mapGetters } from 'vuex';
  import { Picker, EmojiIndex } from 'emoji-mart-vue-fast';
  import data from 'emoji-mart-vue-fast/data/all.json';
  import 'emoji-mart-vue-fast/css/emoji-mart.css';

  import { sendMessage } from '@/api/profilesMethods';
  import DefaultContenteditable from '@/components/base/uiKit/DefaultConteneditable.vue';
  import { ContenteditableMixin } from '@/mixins/ContenteditableMixin';
  import { generateEmojiPickerLocale } from '@/utils/translation/utils';

  export default {
    name: 'MessagesResponser',
    components: {
      DefaultContenteditable,
      ButtonsResponser,
      SavedResponses,
      Picker,
      ScheduleMessage,
    },
    mixins: [closableMixin, ContenteditableMixin],
    props: {
      profile: {
        type: Object,
        required: true,
      },
      isDisabled: {
        type: Boolean,
        required: true,
      },
    },
    data () {
      return {
        active: false,
        message: '',
        emojiesData: new EmojiIndex(data),
      };
    },
    computed: {
      ...mapGetters({
        selectedCredential: 'credentials/selectedCredential',
      }),
      textareaPlaceholder () {
        return this.isDisabled
          ? this.profile.inmail_status === 'pending'
            ? 'This user has not accepted your inMail yet'
            : 'This user has declined your inMail'
          : this.$t('placeholders.message');
      },
      emojiPickerLocales () {
        return generateEmojiPickerLocale();
      },
    },
    methods: {
      handleInput () {
        if (this.message.length <= 1 && this.message[0] === '/') {
          this.showSaved = true;
        }
        this.saveRange();
      },
      insertResponse (response) {
        this.message = response;
        this.showBlocks.responses = false;
      },
      insertEmoji (emoji) {
        this.addTemplate(emoji.native, 'messageArea');
      },
      transformDate (date = new Date()) {
        if (!this.selectedCredential.ui_pc_timezone) {
          return new Date(date - new Date().getTimezoneOffset() * 60 * 1000);
        }
        return date.toISOString().match(/[^T]+T[^:]+[^.]+/g)[0] + '.000Z';
      },
      async sendMessageHandler (date = null) {
        if (date) {
          date = this.transformDate(date);
        }
        try {
          const newMessage = await sendMessage(
            this.profile._id,
            this.selectedCredential._id,
            this.message,
            date
          );
          this.$emit('send', newMessage);
          this.message = '';
        } catch (e) {
          this.$noty.error(e.message);
        }
      },
    },
  };
</script>
<style lang="scss">
.responser {
  position: relative;
  @include flex-row;
  gap: 8px;
  padding: 16px;
  border-radius: 8px;
  background: var(--background-color);
  &_active {
    outline: 1px solid var(--primary-cl);
  }

  &_gray {
    background: var(--neutral-50);
    textarea {
      background: var(--neutral-50);
    }
  }

  &__textarea {
    flex-grow: 1;
    @include text_s;
    border: 0;
    overflow-x: hidden;
    overflow-y: scroll;
    max-height: 200px;
    &::-webkit-scrollbar {
      display: none;
    }
  };

  &__saved-responses {
    position: absolute;
    bottom: calc(100% + 16px);
    left: 0;
  }

  &__emojies {
    position: absolute;
    bottom: calc(100% + 16px);
    right: 0;
  }

  &__schedule {
    position: absolute;
    bottom: calc(100% + 16px);
    right: 0;
  }
}
.emoji-mart {
  background-color: var(--message-test);
}

.emoji-mart-search input {
  background-color: var(--secondary-btn-bg);
  border: 1px solid var(--border-cl);
  color: var(--text-color);
}

.emoji-mart-category-label span {
  background-color: var(--message-test);
  color: var(--text-color);
}
</style>
