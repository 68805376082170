<!--
  © Copyright, Dexima Inc.
  2023 — All rights reserved.
-->
<template>
  <div class="invite-event-step">
    <div class="invite-event-step__error">
      <span
        v-for="(error, key) in messageError"
        :key="key"
        class="text_xs"
        :class="key === 'warningText' ? 'color_warning' : 'color_danger'"
      >
        <i
          v-if="error"
          class="bx bx-info-circle"
        />
        {{ error }}
      </span>
    </div>
    <default-input
      :value.sync="url"
      :placeholder="$t('campaignsPage.editor.steps.inviteEvent.inputPlaceholder')"
      size="s"
      :is-fulled="true"
    />
  </div>
</template>
<script>
  import DefaultInput from '@/components/base/uiKit/DefaultInput';
  import { regexpSearch } from '@/components/updateWorkflow/addSearch/utils';
  export default {
    name: 'InviteToEvent',

    components: {
      DefaultInput,
    },
    props: {
      message: {
        type: String,
        default: '',
      },

      messageError: {
        type: Object,
        default: () => ({
          warningText: '',
          errorText: '',
        }),
      },
    },
    data () {
      return {
        url: this.message || '',
      };
    },
    watch: {
      message () {
        this.url = this.message;
      },
      url () {
        const eventLink = this.url.match(regexpSearch.eventRegex);
        if (eventLink) {
          this.url = eventLink[0];
        }
        this.$emit('update:message', this.url);
      },
    },
  };
</script>
<style lang="scss">
.invite-event-step {
  display: flex;
  flex-flow: column;
  gap: 8px;
  padding: 16px;
  &__error {
    display: flex;
    gap: 4px;
    align-items: center;
  }
}
</style>
