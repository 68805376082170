/*
  © Copyright, Dexima Inc.
  2023 — All rights reserved.
*/
import { getUser } from '@/api/userMethods';
import { getSpace, getSubscribe } from '@/api/spaceMethods';
import { redirectToSetSpaceMixin } from '@/mixins/redirectToSetSpaceMixin';
import { mapGetters } from 'vuex';
import store from '@/store';
import { redirectRules } from '@/data/redirectRules';
import { setBrandScripts } from '@/utils/externalScripts';
import { setServiceBell } from '@/utils/externalScripts/serviceBell';
import { setChatra } from '@/utils/externalScripts/setChatra';
import { impactTrackScript, setTagScript } from '@/utils/externalScripts/setTagScript';

store.commit('mobile/SET_MOBILE_CHECK');
const isMobile = store.getters['mobile/isMobile'];

export const firstFetchMixin = {
  mixins: [redirectToSetSpaceMixin],
  computed: {
    ...mapGetters({
      credentialsList: 'credentials/credentialsList',
      selectedCredential: 'credentials/selectedCredential',
      isAdmin: 'account/isAdmin',
      user: 'user/user',
      account: 'account/account',
      space: 'account/space',
      theme: 'theme',
    }),
  },
  data () {
    return {
      isRedirected: false,
      beforeRoute: null,
      showVendastaBillingEnabledModal: false,
      _redirect: null,
    };
  },
  beforeRouteEnter (to, from, next) {
    next(vm => (vm.beforeRoute = from));
  },
  methods: {
    _setSupport (user, account, theme) {
      const { support } = user.config;
      const supportName = sessionStorage.getItem('support') || support?.name;
      const supportId = sessionStorage.getItem('appId') || support.app_id;
      if (!supportName) {
        return;
      }
      try {
        switch (supportName) {
          case 'no_support':
            break;
          case 'servicebell':
            setServiceBell(user, account);
            break;
          case 'intercome':
            this.setIntercom(supportId);
            break;
          case 'freshchat':
            // eslint-disable-next-line no-undef
            initiateCall(user);
            break;
          default:
            setChatra(account, user, theme);
        }
      } catch (e) {
        // eslint-disable-next-line no-console
        console.error(e);
        this.$noty.error(e.message);
      }
    },
    /**
     * @param {import('@/data/redirectRules').RedirectRule} redirectRule
     * @param {boolean} [toSaved=false]
     */
    _setRedirectTo (redirectRule, toSaved = false) {
      const savedPath = this.beforeRoute?.path;
      if (this.isRedirected) { return; }
      if (savedPath && toSaved && redirectRule.check(savedPath, this.isAdmin)) {
        this._redirect = this.beforeRoute;
        return;
      }
      const currentPath = this.$route.path;
      if (!redirectRule.check(currentPath, this.isAdmin)) {
        this._redirect = redirectRule.defaultRedirect;
      }
    },
    setIntercom (id = 'wtajtfk5') {
      // initialization Intercom
      this.$intercom.boot({
        app_id: id,
        name: this.$route.query.adminName || this.user.first_name,
        email: this.$route.query.admin || this.user.email,
        created_at: Math.floor(new Date(this.user.created_at).getTime() / 1000),
      });
    },

    async _setSelectedCredential (account) {
      if (this.credentialsList.length) {
        let index = -1;
        const id = new URLSearchParams(window.location.search).get('selectedCred');
        if (id) {
          index = this.credentialsList.findIndex(cred => cred._id === id);
        }
        index = index > -1 ? index : 0;
        await this.$store.dispatch('credentials/SET_SELECTED_CREDENTIAL', this.credentialsList[index]);
        this.$socket.emit('change_credentials', {
          credentials: this.credentialsList[index]._id,
        });
        this.$socket.emit('join_prospects_room', {
          token: localStorage.getItem('token'),
          credentials: this.credentialsList[index]._id,
        });
      } else {
        await this.$store.dispatch('credentials/SET_SELECTED_CREDENTIAL', null);
        if (!account.is_billing_enabled) {
          this._setRedirectTo(redirectRules.credentialsAdd);
          return false;
        }
        account.subscription?._id
          ? this._setRedirectTo(redirectRules.credentialsAdd)
          : this._setRedirectTo(redirectRules.pricing);
        return false;
      }
    },

    async _afterBilling () {
      try {
        const response = await getSubscribe();
        if (response) {
          await this.$store.dispatch('account/SET_ACCOUNT', response);
          this._setRedirectTo(redirectRules.billing, true);
          setTagScript();
          impactTrackScript(this.user, response, true);
        } else {
          this._setRedirectTo(redirectRules.pricing);
        }
        return response || null;
      } catch (e) {
        this.$noty.error(e.message);
      }
    },
    async _checkUnpaid () {
      if (this.$route.query.success === 'true') {
        return await this._afterBilling();
      }
      if (this.account.is_paid && this.account.payment_current_usage) { return; }
      await this._setRedirectTo(redirectRules.pricing);
    },

    _initStore () {
      return Promise.all([
        this.$store.dispatch('account/SET_IS_ADMIN'),
        this.$store.dispatch('account/SET_SPACES_LIST'),
        this.$store.dispatch('credentials/SET_CREDENTIALS_LIST'),
        this.$store.dispatch('labels/SET_LABELS_LIST'),
        this.$store.dispatch('labels/SET_SPACE_LABELS_LIST'),
        this.$store.dispatch('labels/SET_TEMPLATE_LABELS_LIST'),
      ]);
    },

    async _initAccount (account, user) {
      if (account.is_billing_enabled && !account.subscription?._id) {
        const responseAccount = await this._checkUnpaid();
        account = responseAccount || account;
      } else if (account.subscription?._id && this.$route.query.success === 'true') {
        impactTrackScript(this.user, account, true);
      }
      await this._initStore();
      if (!isMobile) { this._setSupport(user, account, this.theme); }
      await this._setSelectedCredential(account);
      const promises = [];
      if (this.selectedCredential) {
        promises.push(this.$store.dispatch('integrations/SET_INTEGRATIONS_LIST', this.selectedCredential._id));
      }
      promises.push(this.$store.dispatch('workflows/SET_WORKFLOWS_LIST'));
      await Promise.all(promises);
      if (this.credentialsList.length) {
        this._setRedirectTo(redirectRules.campaigns, true);
        if (!this.space) {
          localStorage.setItem('loggedIn', 'true');
        }
      }
    },

    async _initEmptyAccount (user) {
      await this.$store.dispatch('account/SET_SPACES_LIST');
      if (user.brand !== 'yourleadtool') {
        await this.redirectToSetSpace();
      }
      if (!this.space) {
        localStorage.setItem('loggedIn', 'true');
      }
    },

    async firstFetch (account = null, user = null) {
      account = account || await getSpace();
      user = user || await getUser();
      await Promise.all([
        this.$store.dispatch('user/SET_USER', user),
        this.$store.dispatch('account/SET_ACCOUNT', account),
      ]);
      setBrandScripts(user, account);
      if (account) {
        await this._initAccount(account, user);
      } else {
        await this._initEmptyAccount(user);
      }
      if (this.$route.query.done === 'activation') {
        this.$noty.success('Your email was successfully confirmed');
      }
      if (this._redirect) { await this.$router.push(this._redirect); }
    },
  },
};
